import GenericTable from '@/components/Common/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { checkAccess } from '@/routes/acl';
import { IPriceAnalysis } from '@/services/products/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Menu, TableProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  data: { total: number; results: IPriceAnalysis[] };
  pagination: { page: number; size: number };
  onPagination: (page?: number, size?: number, isSize?: boolean) => Promise<void>;
}

function Table({ data, pagination, isLoading, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IPriceAnalysis>>({});

  const handleChange: TableProps<IPriceAnalysis>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IPriceAnalysis>);
  };

  const columns: ColumnsType<IPriceAnalysis> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 10,
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (date, record) => <Link to={`/price-analysis/${record.id}`}>{date}</Link>
    },
    {
      title: 'Created By',
      dataIndex: 'createdUser',
      key: 'createdUser',
      width: 7,
      sortOrder: sortedInfo.columnKey === 'createdUser' ? sortedInfo.order : null,
      sorter: (a, b) => (a.createdUser || '').localeCompare(b.createdUser || '')
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 7,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt)
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 7,
      render: (text: string) => convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT),
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null,
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    }
  ];

  if (
    checkAccess('READ_PRICE_ANALYSIS') ||
    checkAccess('UPDATE_PRICE_ANALYSIS') ||
    checkAccess('UPDATE_PRICE_GROUP')
  ) {
    columns.push({
      title: 'Action',
      width: 3,
      key: 'action',
      fixed: 'right',
      render: (record: IPriceAnalysis) => {
        const menuItems = [];

        if (checkAccess('READ_PRICE_ANALYSIS')) {
          menuItems.push({
            key: 'view-price_analysis',
            label: (
              <Tooltip title="View Price Analysis">
                <Link to={`/price-analysis/${record.id}`}>View</Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_PRICE_ANALYSIS')) {
          menuItems.push({
            key: 'update-price_analysis',
            label: (
              <Tooltip title="Update Price Analysis">
                <Link to={`/price-analysis/details/${record.id}`}>Edit</Link>
              </Tooltip>
            )
          });
        }

        if (checkAccess('UPDATE_PRICE_GROUP')) {
          menuItems.push({
            key: 'update-price-group',
            label: (
              <Tooltip title="Update Price Group">
                <Link to={`/price-groups/analysis/${record.id}`}>Price Group</Link>
              </Tooltip>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    });
  }

  return (
    <>
      <GenericTable
        columns={columns}
        data={data.results}
        isLoading={isLoading}
        tableName="price-analysis-list"
        toSort={handleChange}
        hideDefaultPagination={true}
        scroll={{ x: 1000, y: '75vh' }}
        pagination={{
          page: pagination.page,
          total: data.total,
          size: pagination.size,
          onPagination
        }}
      />
    </>
  );
}

export default Table;
