import AppContent from '@/components/Common/Content/Content';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { update_price_group_price_by_price_analysis } from '@/services/products/mutations';
import { get_price_groups_by_location } from '@/services/products/queries';
import { ICreatePriceGroup } from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import { Alert, Button, Form, message, Select, Spin } from 'antd';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function UpdatePriceGroupByAnalysis() {
  const params = useParams();
  const analysisId = Number(params.id);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [priceGroups, setPriceGroups] = useState<ICreatePriceGroup[]>([]);

  if (!params.id) {
    return <Navigate to="/price-analysis" replace />;
  }

  const breadcrumbs = [
    { label: 'Price Analysis', link: '/price-analysis' },
    { label: 'Update Price Groups' }
  ];

  async function handleLocationChange(locationId?: number) {
    try {
      setIsLoading(true);
      if (!locationId) {
        setPriceGroups([]);
        form.setFieldsValue({ priceGroupId: undefined });
        return;
      }

      const { data } = await get_price_groups_by_location(locationId);
      setPriceGroups(data);

      if (data.length > 0) {
        form.setFieldsValue({ priceGroupId: data[0].id });
      } else {
        message.info('No price groups found for this location');
        form.setFieldsValue({ priceGroupId: undefined });
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdatePrice() {
    const values = await form.validateFields();

    try {
      setIsLoading(true);
      await update_price_group_price_by_price_analysis(values.priceGroupId, analysisId);
      message.info('Price for the selected price group has been updated successfully');
      navigate('/price-analysis');
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbs}>
        <Alert
          closable
          showIcon
          type="info"
          message="Information"
          description={
            <ul className="pl-0">
              <li>The prices to update come from the selected price analysis.</li>
              <li>
                For valid products, old prices in the price group will be removed and replaced with
                new prices (other prices stay the same).
              </li>
              <li>If today's price is 0, it will not be updated.</li>
              <li>The new price will be the outlet's purchase price.</li>
            </ul>
          }
        />

        <div className="mt-2">
          <Form form={form} layout="vertical">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4">
              <LocationSearchV2
                hasParentFormItem={false}
                name={'locationId'}
                required
                onSelect={handleLocationChange}
              />
              <Form.Item
                label="Price Group"
                name="priceGroupId"
                rules={[{ required: true, message: 'Please select price group!' }]}>
                <Select placeholder="Select a price group!">
                  {priceGroups.map((priceGroup: any) => (
                    <Select.Option key={priceGroup.id} value={priceGroup.id}>
                      {priceGroup.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="mt-2">
              <Button type="primary" onClick={handleUpdatePrice}>
                Update
              </Button>
            </div>
          </Form>
        </div>
      </AppContent>
    </Spin>
  );
}

export default UpdatePriceGroupByAnalysis;
