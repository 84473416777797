import { CSSProperties, useEffect, useRef, useState } from 'react';
import DataGrid, {
  type ColumnOrColumnGroup,
  DataGridHandle,
  RenderCellProps
} from 'react-data-grid';

import { Button, Input, InputNumber, message, Modal, Tag } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProductSearchForNonForm from '@/components/Common/CustomSearch/Products/ProductSearchFoNonForm';
import UnitSearchForNonForm from '@/components/Common/CustomSearch/Units/UnitSearchForNonForm';
import {
  ICreatePurchasePriceAnalysis,
  ICreateSalePriceAnalysis,
  IOutletPricingByDate,
  IProductDetails
} from '@/services/products/types';
import getErrorMessage from '@/utils/getError';
import { useNavigate } from 'react-router-dom';
import { IRow } from '../grid.types';
import { emptyRow, GRID_MIN_HEIGHT, GRID_ROW_HEIGHT } from '../create/constant';
import { calculateRowValues } from '../create/services';
import { update_price_analysis_mutation } from '@/services/products/mutations';
import { getUnit } from '@/services';
import { fetchProductAllPriceDetails } from '../services';

interface Props {
  id: number;
  date?: string;
  rows: IRow[];
  setRows: React.Dispatch<React.SetStateAction<IRow[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  priceAnalysis?: IOutletPricingByDate;
}

const UpdatePriceAnalysisGrid = ({
  date,
  setIsLoading,
  rows,
  setRows,
  id,
  priceAnalysis
}: Props) => {
  // State to manage the grid data
  const [gridHeight, setGridHeight] = useState(GRID_MIN_HEIGHT);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const navigate = useNavigate();

  // {rowIndex: productId}
  const gridRef = useRef<DataGridHandle>(null);

  useEffect(() => {
    const height = Math.min((rows.length + 1) * GRID_ROW_HEIGHT, 600);
    if (height > GRID_MIN_HEIGHT - 100) {
      setGridHeight(height);
    }

    // Scroll to the last row when rows are updated
    const lastRowIndex = rows.length - 1;
    if (gridRef.current && lastRowIndex >= 0) {
      const scrollTop = lastRowIndex * GRID_ROW_HEIGHT;
      gridRef.current.element?.scrollTo({ top: scrollTop, behavior: 'smooth' });
    }
  }, [rows]);

  async function onProductChange(
    productId: number,
    product: IProductDetails,
    props: RenderCellProps<IRow>
  ) {
    try {
      if (!date) {
        message.error('Please select date');
        return;
      }

      setIsLoading(true);
      const allUnits = product.productUnits.map((p) => p.unitId);
      const defaultUnit = product.productUnits.find((u) => u.isDefault);

      let baseUnitMultiplier = 1;
      const currentUnitId = defaultUnit?.unitId || allUnits.length ? allUnits[0] : undefined;

      if (currentUnitId) {
        const unitDetails = await getUnit(currentUnitId);
        baseUnitMultiplier = unitDetails.baseUnitMultiplier;
      }

      const products = [{ id: productId, baseUnitMultiplier }];
      const productDetails = await fetchProductAllPriceDetails(products, date, priceAnalysis);
      const rowData = productDetails[productId];

      if (!rowData) {
        message.error('No data found for this product. Consult IT team for help.');
        return;
      }

      const updatedRow = {
        ...props.row,
        ...rowData,
        productId,
        productName: product.name,
        productUnits: allUnits,
        unitId: currentUnitId
      };

      const calculatedRows = calculateRowValues(updatedRow);
      props.onRowChange(calculatedRows);

      const lastRow = rows[rows.length - 1];
      const isLastRowFull = Boolean(lastRow.productId && lastRow.productName);

      if (isLastRowFull || props.rowIdx === rows.length - 1) {
        addEmptyRow();
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  function addEmptyRow() {
    setRows((prevRows) => [...prevRows, emptyRow]);
  }

  const deleteRow = (rowId?: number) => {
    setRows((prev) => {
      const filteredRows = prev.filter((row) => row.productId !== rowId);
      return filteredRows;
    });
  };

  function renderInputNumber({ onRowChange, column, row }: RenderCellProps<IRow>) {
    const rowValue = row as Record<string, any>;
    const isTodayPrice = column.key === 'todayPrice';

    return (
      <div
        className="w-full h-full custom-grid-input"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}>
        <InputNumber
          className="antd-grid-input"
          value={rowValue[column.key]}
          onChange={(value) => {
            const rowData = { ...row, [column.key]: value || 0 };
            if (isTodayPrice) rowData.sellPurchasePrice = rowData.todayPrice;
            const updatedValue = calculateRowValues(rowData);
            onRowChange(updatedValue);
          }}
          min={0}
        />
      </div>
    );
  }

  function renderInput({ onRowChange, column, row }: RenderCellProps<IRow>) {
    const rowValue = row as Record<string, any>;

    return (
      <div
        className="w-full h-full custom-grid-input"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}>
        <Input
          className="antd-grid-input"
          value={rowValue[column.key]}
          onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value || '' })}
          min={0}
        />
      </div>
    );
  }

  function renderReadonlyInput(value: string | number, style?: CSSProperties) {
    return (
      <div className="w-full h-full custom-grid-input" onClick={(e) => e.stopPropagation()}>
        <Input value={value} className="antd-grid-input" style={style} readOnly />
      </div>
    );
  }

  function handleNewRow() {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IRow);
    const isRowFilled = Boolean(lastRow.productId && lastRow.productName);

    if (!isRowFilled && rows.length > 0) {
      message.destroy();
      message.error('Please fill current row before adding new one');
      return;
    }

    addEmptyRow();
  }

  function handleSubmitClick(type: 'check' | 'save') {
    const lastIndex = rows.length - 1;
    const lastRow = lastIndex >= 0 ? rows[lastIndex] : ({} as IRow);
    const isRowFilled = Boolean(lastRow.productId && lastRow.productName);

    // If last row is not filled, exclude it
    const updatedRows = isRowFilled ? rows : rows.slice(0, -1);
    if (updatedRows.length === 0) {
      message.destroy();
      message.error('Please fill atleast one row');
      return;
    }

    const someRowEmpty = updatedRows.some((row) => !row.productId || !row.productName);
    if (someRowEmpty) {
      message.destroy();
      message.error('Please fill all rows or remove empty rows');
      return;
    }

    setOpenSubmitModal(type === 'check');
    return updatedRows;
  }

  async function onFinish() {
    try {
      setIsLoading(true);

      const updatedRows = handleSubmitClick('save');
      if (!updatedRows) return;

      const purchaseCreateList: ICreatePurchasePriceAnalysis[] = [];
      const sellCreateList: ICreateSalePriceAnalysis[] = [];

      for (const row of updatedRows) {
        const productId = row.productId as number;
        const unitId = row.unitId as number;

        purchaseCreateList.push({
          productId,
          id: row.purchaseId,
          unitId,
          analysisId: id,
          purchasePrice: row.purchasePrice,
          kalimatiRate: row.kalimatiRate,
          morningPurchase: row.morningPurchase,
          kalimatiSurveyRate: row.kalimatiSurveyRate,
          yesterdaySellingRate: row.yesterdaySellingRate,
          yesterdaySoldQty: row.yesterdaySoldQty,
          totalStock: row.totalStock,
          todayPrice: row.todayPrice,
          openingStock: row.openingStock,
          totalOrder: row.totalOrder,
          estimatedData: row.estimatedData
        });

        sellCreateList.push({
          productId,
          unitId,
          id: row.sellId,
          analysisId: id,
          purchasePrice: row.sellPurchasePrice,
          yesterdayPrice: row.sellYesterdayPrice,
          todayPrice: row.sellTodayPrice
        });
      }

      const format = { date: date as string, purchase: purchaseCreateList, sale: sellCreateList };

      await update_price_analysis_mutation(id, format);
      message.success('Price Analysis created successfully');
      navigate('/price-analysis');
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const columnsWithActions: ColumnOrColumnGroup<IRow>[] = [
    {
      key: 'id',
      name: 'S.N.',
      frozen: true,
      width: 60,
      minWidth: 60,
      renderCell(props) {
        return <strong>{props.rowIdx + 1}</strong>;
      }
    },
    {
      key: 'delete',
      name: 'Action',
      frozen: true,
      width: 60,
      minWidth: 60,
      cellClass: 'p-0',
      renderCell(props) {
        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
              deleteRow(props.row.productId);
            }}
            className="flex justify-center items-center cursor-pointer h-full">
            <DeleteOutlined style={{ fontSize: 16 }} className="!text-red-500 [&>svg]:!mt-0" />
          </div>
        );
      }
    },
    {
      name: 'Purchase',
      headerCellClass: 'text-center',
      children: [
        {
          key: 'productName',
          name: 'Product',
          width: 300,
          minWidth: 300,
          cellClass: 'p-0',
          renderCell(props) {
            return (
              <div onClick={(e) => e.stopPropagation()} className="w-full">
                <ProductSearchForNonForm
                  className="w-full"
                  allowClear={false}
                  disabled={!date}
                  defaultValue={props.row.productId}
                  onSelect={async (productId, product) => {
                    const isExist = rows.some((row) => row.productId === productId);
                    if (isExist) {
                      message.destroy();
                      message.error('Product already added');
                      props.onRowChange(emptyRow);
                      throw new Error('Product already added');
                    }

                    await onProductChange(productId, product, props);
                  }}
                />
              </div>
            );
          }
        },
        {
          key: 'unitId',
          name: 'Unit',
          cellClass: 'p-0',
          width: 150,
          minWidth: 150,
          renderCell({ row, onRowChange }) {
            return (
              <div onClick={(e) => e.stopPropagation()} className="w-full">
                <UnitSearchForNonForm
                  allowClear={false}
                  defaultValue={row.unitId}
                  disabled
                  selectedProductUnitIds={row.productUnits || []}
                  onSelect={(id) => onRowChange({ ...row, unitId: id })}
                />
              </div>
            );
          }
        },
        {
          key: 'purchasePrice',
          name: 'Purchase Price (Rs)',
          width: 100,
          minWidth: 100,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'kalimatiRate',
          name: 'Kalimati Rate (Rs)',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header',
          cellClass: 'p-0',
          renderCell: renderInputNumber
        },
        {
          key: 'morningPurchase',
          name: 'Morning Purchase (Rs)',
          cellClass: 'p-0',
          width: 120,
          minWidth: 120,
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'kalimatiSurveyRate',
          name: 'Kalimati Survey Rate (Rs)',
          cellClass: 'p-0',
          width: 130,
          minWidth: 130,
          headerCellClass: 'text-wrap-header',
          renderCell: renderInput
        },
        {
          key: 'yesterdaySellingRate',
          name: 'Yesterday Selling Rate (Rs)',
          cellClass: 'p-0',
          width: 133,
          minWidth: 133,
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'yesterdaySoldQty',
          name: 'Yesterday Sold Qty',
          width: 120,
          minWidth: 120,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'totalStock',
          name: 'Total Stock',
          width: 110,
          minWidth: 110,
          headerCellClass: 'text-wrap-header',
          cellClass: 'reset-padding',
          renderCell: ({ row }) => renderReadonlyInput(row.totalStock)
        },
        {
          key: 'todayPrice',
          name: 'Today Price (Rs)',
          width: 100,
          minWidth: 100,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'isPriceChanged',
          name: 'Is Price Changed?',
          width: 100,
          minWidth: 100,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: ({ row }) => (
            <div className="flex justify-center">
              <Tag color={row.isPriceChanged ? 'red' : 'green'}>
                {row.isPriceChanged ? 'Changed' : 'Same'}
              </Tag>
            </div>
          )
        },
        {
          key: 'marginAmount',
          name: 'Margin (Rs)',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'marginPercentage',
          name: 'Margin (%)',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'openingStock',
          name: 'Opening Stock',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'totalOrder',
          name: 'Total Order',
          width: 100,
          minWidth: 100,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'estimatedData',
          name: 'Estimated Data',
          width: 100,
          minWidth: 100,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        }
      ]
    },
    {
      name: 'FreshKtm Outlet',
      headerCellClass: 'text-center text-white bg-red-500',
      children: [
        {
          key: 'sellPurchasePrice',
          name: 'Purchase Price',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header',
          cellClass: 'p-0',
          renderCell: renderInputNumber
        },
        {
          key: 'isPurchasePriceChanged',
          name: 'Is Purchase Price Changed?',
          width: 130,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: ({ row }) => (
            <div className="flex justify-center">
              <Tag color={row.isPurchasePriceChanged ? 'red' : 'green'}>
                {row.isPurchasePriceChanged ? 'Changed' : 'Same'}
              </Tag>
            </div>
          )
        },
        {
          key: 'sellYesterdayPrice',
          name: 'Yesterday Selling Rate (Rs)',
          width: 140,
          minWidth: 140,
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'sellTodayPrice',
          name: 'Today Selling Rate (Rs)',
          width: 130,
          minWidth: 130,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: renderInputNumber
        },
        {
          key: 'isSellRateChanged',
          name: 'Is Sell Rate Changed?',
          width: 130,
          minWidth: 130,
          cellClass: 'p-0',
          headerCellClass: 'text-wrap-header',
          renderCell: ({ row }) => (
            <div className="flex justify-center">
              <Tag color={row.isSellRateChanged ? 'red' : 'green'}>
                {row.isSellRateChanged ? 'Changed' : 'Same'}
              </Tag>
            </div>
          )
        },
        {
          key: 'marginSellAmount',
          name: 'Margin (Rs)',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header'
        },
        {
          key: 'marginSellPercentage',
          name: 'Margin (%)',
          width: 100,
          minWidth: 100,
          headerCellClass: 'text-wrap-header'
        }
      ]
    }
  ];

  return (
    <div>
      <Modal visible={openSubmitModal} onCancel={() => setOpenSubmitModal(false)} onOk={onFinish}>
        Do you want to update this price analysis?
      </Modal>

      <DataGrid
        ref={gridRef}
        className="rdg-light"
        style={{ height: gridHeight }}
        columns={columnsWithActions}
        rows={rows}
        rowHeight={GRID_ROW_HEIGHT}
        headerRowHeight={50}
        renderers={{
          noRowsFallback: (
            <div className="relative col-span-full">
              <div className="sticky top-0 h-full left-0 w-screen flex items-center justify-center">
                <p>No products have been added yet.</p>
              </div>
            </div>
          )
        }}
        defaultColumnOptions={{ resizable: true }}
        onRowsChange={setRows}
        enableVirtualization
      />

      <div className="mt-2.5">
        <Button
          disabled={!date}
          type="dashed"
          onClick={handleNewRow}
          block
          style={{ width: '100%' }}>
          <PlusOutlined /> Add field
        </Button>
      </div>

      <div className="mt-2.5 flex justify-end gap-2.5">
        {/* <Button type="default" onClick={() => console.log(rows)}>
          Console Product
        </Button> */}

        <Button
          type="primary"
          onClick={() => handleSubmitClick('check')}
          disabled={!date || !rows.length}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default UpdatePriceAnalysisGrid;
