import {
  ICreatePriceAnalysisPayload,
  ICreatePriceGroup,
  ICreatePriceGroupRequest,
  ILotsConversionData,
  ILotsConversionResponse,
  IProductCreateRequest,
  IProductCreateResponse,
  IProductPriceGroupResponse,
  IProductStatusResponse,
  IProductUpdateRequest,
  IUpdateProductPrice,
  IUpdateProductStatus
} from './types';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import { ICreateCommonResponse } from '../category/types';
import { ILot } from '../purchases/types';

export const create_products_mutation = async (values: IProductCreateRequest) => {
  const response: AxiosResponse<IProductCreateResponse, any> = await http.store('products', values);
  return response;
};

export const update_products_mutation = async (values: IProductUpdateRequest) => {
  const response: AxiosResponse<ICreateCommonResponse, any> = await http.update(
    'products/' + values.id,
    values
  );
  return response;
};

export const merge_products_mutation = async (values: { lotIds: number[]; grade: string }) => {
  const response: AxiosResponse<ILot, any> = await http.store('products/merge-lots/', values);
  return response;
};

export const diverge_products_mutation = async (values: {
  lotId: number;
  lots: {
    grade: string;
    qty: number;
  }[];
}) => {
  const response: AxiosResponse<ILot[], any> = await http.store(
    'products/distribute-lots/',
    values
  );
  return response;
};

export const upload_price_group_mutation = async (values: any) => {
  const response: AxiosResponse<any, any> = await http.store(
    'prices/upload/' + values.id,
    values.file
  );
  return response;
};
export const create_price_group_mutation = async (values: ICreatePriceGroupRequest) => {
  const response: AxiosResponse<ICreatePriceGroup, any> = await http.store('prices/groups', values);
  return response;
};

export const upload_default_unitId = async (values: {
  productId: number | string;
  unitId: number;
}) => {
  const response: AxiosResponse<any, any> = await http.store(
    'products/product/' + values.productId + '/default-unit/' + values.unitId
  );
  return response;
};

export const convert_product_lot = async (values: ILotsConversionData) => {
  const response: AxiosResponse<ILotsConversionResponse, any> = await http.store(
    '/products/convert-lots',
    values
  );
  return response;
};

export const update_product_status_mutation = async (values: IUpdateProductStatus) => {
  const response: AxiosResponse<IProductStatusResponse, any> = await http.update(
    '/products-status/' + values.id,
    values
  );
  return response.data;
};

export const update_product_pricegroup_mutation = async (values: IUpdateProductPrice[]) => {
  const response: AxiosResponse<IProductPriceGroupResponse, any> = await http.update(
    '/prices/prices',
    { prices: values }
  );
  return response.data;
};

export const create_lots_clear_mutation = async (locationId: number) => {
  const response = await http.store('lot-0', {
    locationId
  });

  return response.data;
};

export const create_price_analysis_mutation = async (data: ICreatePriceAnalysisPayload) => {
  const response = await http.store('price-analysis', data);
  return response.data;
};

export const update_price_analysis_mutation = async (
  id: number,
  data: ICreatePriceAnalysisPayload
) => {
  const response = await http.update('price-analysis/' + id, data);
  return response.data;
};

export const update_price_group_price_by_price_analysis = async (
  priceGroupId: number,
  analysisId: number
) => {
  const response = await http.update('price-analysis/update-price-group-prices', {
    priceGroupId,
    analysisId
  });

  return response.data;
};
