import { Button } from 'antd';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { find_invoice_number } from '../../../../store/localstorage/preferences';
import { convert_string_to_nepali_date_string } from '../../../../utils/nepaliDateConverter';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { ISellInvoice } from '../../../../services/invoice/types';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { getUserData } from '../../../../utils/auth.utils';
import { useMutation } from '@tanstack/react-query';
import { update_sell_print_count_mutation } from '../../../../services/sell/mutations';
import { numberToWords } from '@/utils/numberToWords';
import moment from 'moment';

const SellInvoice: React.FC<ISellInvoice> = ({
  sellDetails,
  customerDetails,
  lines,
  firstCreatedByUserDetails,
  invoiceLayouts,
  isAllSellLinesReturned,
  handleModalClose
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();
  const content = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      // if (sellDetails.printCount === 0) await updatePrintCountMutation.mutateAsync(sellDetails.id);
      await updatePrintCountMutation.mutateAsync(sellDetails.id);
    }
  });

  const updatePrintCountMutation = useMutation(update_sell_print_count_mutation, {
    onSuccess: () => {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const headers = [
    'S.N',
    'HS Code',
    'Description',
    'Quantity',
    'Unit Price(NPR)',
    'Discount(NPR)',
    'VAT(NPR)',
    'Amount(NPR)'
  ];

  const linesTotalDiscount = lines.reduce((acc, curr) => acc + Number(curr.discount), 0);
  const isPrintCopy = sellDetails.printCount > 0;
  const printedTime = moment().format('DD/MM/YYYY hh:mm:ss A');
  const numberToWordsString = numberToWords(sellDetails.totalAmount);
  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        <div style={{ color: 'black' }}>
          <div className="text-center mt-3">
            <div className="font-bold text-xl">
              {content?.headerText || 'FreshKtm Agro Pvt. Ltd'}
            </div>
            <div>{content?.subHeaderText || 'Suryabhinayak, Bhaktapur, Nepal'}</div>
            <div>{sellDetails.printLocation?.name}</div>
            <div className="font-bold text-sm">PAN:- {find_invoice_number()}</div>
            <div className=" mt-2 relative">
              <div className="font-bold text-sm">
                {isAllSellLinesReturned
                  ? 'Cancel Sales Invoice'
                  : isPrintCopy
                  ? 'INVOICE'
                  : 'TAX INVOICE'}
              </div>

              {!isAllSellLinesReturned && isPrintCopy && (
                <div className="absolute -top-2.5 right-[20%] text-[13px] text-black/80 font-serif">
                  Copy of Original {sellDetails.printCount}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mt-3 px-8">
            <div>
              <span>{'Invoice No.'}</span> :{' '}
              <span className="italic font-bold">{sellDetails?.financialReference} </span>
              <div>
                <span>{'Customer Name'}</span>:{' '}
                <span className="font-bold uppercase">{customerDetails?.name}</span>
              </div>
              <div>
                <span>{'Customer Address'}</span>:{' '}
                <span className="font-bold uppercase">
                  {customerDetails?.address} {customerDetails?.city},{customerDetails?.country}
                </span>
              </div>
              <div>
                <span>Customer Number</span>:{' '}
                <span className="font-bold uppercase">{customerDetails?.phone}</span>
              </div>
            </div>
            <div className="text-right">
              <div>
                <span>{content?.nepaliDateLabel || 'Invoice Miti'}</span> :{' '}
                {convert_string_to_nepali_date_string(sellDetails?.createdAt || '')}
              </div>
              <div>
                <span>{content?.dateLabel || 'Invoice Date'}</span> :{' '}
                {convertUTCStringtoLocalString(sellDetails?.createdAt || '', 'YYYY-MM-DD')}
              </div>
              <div>
                <span>{content?.transactionDateLabel || 'Transaction Date'}</span> :{' '}
                {convertUTCStringtoLocalString(sellDetails?.createdAt || '')}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center px-8">
            <div>
              <span>{'Customer PAN'}</span>:{' '}
              <span className="font-bold uppercase">
                {customerDetails?.panNumber ? customerDetails.panNumber : ''}
              </span>
            </div>
            <div>
              <span>{'Payment Status'}</span>:{' '}
              <span className="uppercase">{sellDetails?.paymentStatus}</span>
            </div>
          </div>
          {/* Table Start */}
          <div className="flex justify-center items-center">
            <table width={'95%'}>
              {headers.map((curr, ind) => {
                return (
                  <th key={ind} style={thStyle}>
                    {curr}
                  </th>
                );
              })}
              {lines.map((currdata, ind) => {
                return (
                  <tr key={currdata.id}>
                    <td style={thStyle}>
                      <div className="text-center">{ind + 1}.</div>
                    </td>
                    <td style={thStyle}>
                      <div className="mx-1">{`${currdata?.hsCode || 'N/A'}`}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="ml-1">{currdata.productName}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{`${currdata.quantity} ${currdata.shortName}`}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{currdata.unitPrice.toFixed(2)}</div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{currdata.discount.toFixed(2)}</div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">
                        {currdata.vat != undefined ? currdata.vat.toFixed(2) : ''}
                      </div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">
                        {currdata.vat
                          ? (currdata.totalAmount - currdata.vat).toFixed(2)
                          : currdata.totalAmount.toFixed(2)}
                      </div>{' '}
                    </td>
                  </tr>
                );
              })}
              {lines.length < 16 &&
                [...Array(16 - lines.length)].map((curr: any) => {
                  return (
                    <tr key={curr} style={{ height: '1.5rem' }}>
                      <td style={tdStyle}>
                        <div className="text-center">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="ml-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={2} style={thStyle}>
                  <span>{'Total Quantity'}</span>:
                </td>
                <td colSpan={1} style={thStyle}>
                  <div className="text-center">{sellDetails.totalQuantity}</div>
                </td>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Total Amount'}</div>
                </td>
                <td colSpan={1} style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(
                    sellDetails.totalTaxable +
                      sellDetails.totalNonTaxable +
                      sellDetails.totalDiscount -
                      (sellDetails.shipping - sellDetails.shippingTax)
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={3} rowSpan={6} style={thStyle}>
                  <div className="text-start">{'In Words: ' + numberToWordsString}</div>
                </td>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Discount'}</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(
                    sellDetails.totalDiscount > 0 ? sellDetails.totalDiscount : linesTotalDiscount
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Shipping Charge'}</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(sellDetails.shipping - sellDetails.shippingTax)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">Taxable</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(sellDetails.totalTaxable)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Non Taxable'}</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(sellDetails.totalNonTaxable)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'VAT'}</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(sellDetails.totalVat)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Net Amount'}</div>
                </td>
                <td style={thStyle} className="text-right pr-1">
                  {nepaliNumberFormatter(sellDetails.totalAmount)}
                </td>
              </tr>
              <tr>
                <td colSpan={headers.length} style={thStyle}>
                  <div>Remarks:</div>
                </td>
              </tr>
            </table>
          </div>
          {/* Table End */}
          <div className="mt-12 flex justify-between  px-8">
            <div>
              <div>..........................................</div>
              <div>{'Received By'}</div>
            </div>
            <div className="font-bold">www.freshktm.com</div>
            <div>
              <div>........................................................................</div>
              <div>
                For:
                <span>{'FreshKtm Agro Pvt. Ltd.'}</span>
              </div>
              <div>
                Finalized By:
                <span className="capitalize">
                  {sellDetails?.createdByName ? sellDetails.createdByName : ''}
                </span>
              </div>
              {firstCreatedByUserDetails && (
                <div>
                  Created By:
                  <span className="capitalize">
                    {firstCreatedByUserDetails?.name ? firstCreatedByUserDetails.name : ''}
                  </span>
                </div>
              )}
              <div>
                Printed By:{' '}
                <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between gap-2 px-8 my-2">
            <div>
              <span className="font-bold">Printed At:</span> {printedTime}
            </div>
            <div className="font-bold">E. & O.E</div>
          </div>
        </div>
        {/* For First Print */}
        {sellDetails.printCount === 0 && !isAllSellLinesReturned && (
          <>
            <div className="pagebreak"></div>
            <div style={{ color: 'black' }}>
              <div className="text-center mt-3">
                <div className="font-bold text-xl">
                  {content?.headerText || 'FreshKtm Agro Pvt. Ltd.'}
                </div>
                <div>{content?.subHeaderText || 'Suryabhinayak, Bhaktapur, Nepal'}</div>
                <div>{sellDetails.printLocation?.name}</div>
                <div className="font-bold text-sm">PAN:- {find_invoice_number()}</div>
                <div className="font-bold text-sm mt-2">
                  {isAllSellLinesReturned ? 'Cancel Sales Invoice' : 'INVOICE'}
                </div>
              </div>
              <div className="flex justify-between items-center mt-3 px-8">
                <div>
                  <span>{'Invoice No.'}</span> :{' '}
                  <span className="italic font-bold">{sellDetails?.financialReference} </span>
                  <div>
                    <span>{'Customer Name'}</span>:{' '}
                    <span className="font-bold uppercase">{customerDetails?.name}</span>
                  </div>
                  <div>
                    <span>{'Customer Address'}</span>:{' '}
                    <span className="font-bold uppercase">
                      {customerDetails?.address} {customerDetails?.city},{customerDetails?.country}
                    </span>
                  </div>
                  <div>
                    <span>Customer Number</span>:{' '}
                    <span className="font-bold uppercase">{customerDetails?.phone}</span>
                  </div>
                </div>
                <div className="text-right">
                  <div>
                    <span>{content?.nepaliDateLabel || 'Invoice Miti'}</span> :{' '}
                    {convert_string_to_nepali_date_string(sellDetails?.createdAt || '')}
                  </div>
                  <div>
                    <span>{content?.dateLabel || 'Invoice Date'}</span> :{' '}
                    {convertUTCStringtoLocalString(sellDetails?.createdAt || '', 'YYYY-MM-DD')}
                  </div>
                  <div>
                    <span>{content?.transactionDateLabel || 'Transaction Date'}</span> :{' '}
                    {convertUTCStringtoLocalString(sellDetails?.createdAt || '')}
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center px-8">
                <div>
                  <span>{'Customer PAN'}</span>:{' '}
                  <span className="font-bold uppercase">
                    {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                  </span>
                </div>
                <div>
                  <span>{'Payment Status'}</span>:{' '}
                  <span className="uppercase">{sellDetails?.paymentStatus}</span>
                </div>
              </div>
              {/* Table Start */}
              <div className="flex justify-center items-center">
                <table width={'95%'}>
                  {headers.map((curr, ind) => {
                    return (
                      <th key={ind} style={thStyle}>
                        {curr}
                      </th>
                    );
                  })}
                  {lines.map((currdata, ind) => {
                    return (
                      <tr key={currdata.id}>
                        <td style={thStyle}>
                          <div className="text-center">{ind + 1}.</div>
                        </td>
                        <td style={thStyle}>
                          <div className="mx-1">{`${currdata?.hsCode || 'N/A'}`}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="ml-1">{currdata.productName}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{`${currdata.quantity} ${currdata.shortName}`}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{currdata.unitPrice.toFixed(2)}</div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{currdata.discount.toFixed(2)}</div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">
                            {currdata.vat != undefined ? currdata.vat.toFixed(2) : ''}
                          </div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">
                            {currdata.vat
                              ? (currdata.totalAmount - currdata.vat).toFixed(2)
                              : currdata.totalAmount.toFixed(2)}
                          </div>{' '}
                        </td>
                      </tr>
                    );
                  })}
                  {lines.length < 16 &&
                    [...Array(16 - lines.length)].map((curr: any) => {
                      return (
                        <tr key={curr} style={{ height: '1.5rem' }}>
                          <td style={tdStyle}>
                            <div className="text-center">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="ml-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan={2} style={thStyle}>
                      <span>{'Total Quantity'}</span>:
                    </td>
                    <td colSpan={1} style={thStyle}>
                      <div className="text-center">{sellDetails.totalQuantity}</div>
                    </td>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Total Amount'}</div>
                    </td>
                    <td colSpan={1} style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(
                        sellDetails.totalTaxable +
                          sellDetails.totalNonTaxable +
                          sellDetails.totalDiscount -
                          (sellDetails.shipping - sellDetails.shippingTax)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} rowSpan={6} style={thStyle}>
                      <div className="text-start">{'In Words: ' + numberToWordsString}</div>
                    </td>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Discount'}</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(
                        sellDetails.totalDiscount > 0
                          ? sellDetails.totalDiscount
                          : linesTotalDiscount
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Shipping Charge'}</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(sellDetails.shipping - sellDetails.shippingTax)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">Taxable</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(sellDetails.totalTaxable)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Non Taxable'}</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(sellDetails.totalNonTaxable)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'VAT'}</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(sellDetails.totalVat)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Net Amount'}</div>
                    </td>
                    <td style={thStyle} className="text-right pr-1">
                      {nepaliNumberFormatter(sellDetails.totalAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={headers.length} style={thStyle}>
                      <div>Remarks:</div>
                    </td>
                  </tr>
                </table>
              </div>
              {/* Table End */}
              <div className="mt-12 flex justify-between  px-8">
                <div>
                  <div>..........................................</div>
                  <div>{'Received By'}</div>
                </div>
                <div className="font-bold">www.freshktm.com</div>
                <div>
                  <div>
                    ........................................................................
                  </div>
                  <div>
                    For:
                    <span>{'FreshKtm Agro Pvt. Ltd.'}</span>
                  </div>
                  <div>
                    Finalized By:
                    <span className="capitalize">
                      {sellDetails?.createdByName ? sellDetails.createdByName : ''}
                    </span>
                  </div>
                  {firstCreatedByUserDetails && (
                    <div>
                      Created By:
                      <span className="capitalize">
                        {firstCreatedByUserDetails?.name ? firstCreatedByUserDetails.name : ''}
                      </span>
                    </div>
                  )}
                  <div>
                    Printed By:{' '}
                    <span className="capitalize">
                      {loggedInUser?.name ? loggedInUser.name : ''}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between gap-2 px-8 my-2">
                <div>
                  <span className="font-bold">Printed At:</span> {printedTime}
                </div>
                <div className="font-bold">E. & O.E</div>
              </div>
            </div>
          </>
        )}
        {/* For First End */}
      </div>
    </>
  );
};

const thStyle = {
  border: '1px solid black'
};

const tdStyle = {
  borderLeft: '1px solid black',
  borderRight: '1px solid black'
};

export default SellInvoice;
