import { useRef } from 'react';
import { ISellInvoice } from '../../../../services/invoice/types';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@tanstack/react-query';
import { update_sell_print_count_mutation } from '../../../../services/sell/mutations';
import { find_invoice_number } from '../../../../store/localstorage/preferences';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { convert_string_to_nepali_date_string } from '../../../../utils/nepaliDateConverter';
import moment from 'moment';
import { getShortLastName, getShortName } from './services';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { Button } from 'antd';
import { getUserData } from '../../../../utils/auth.utils';

const BillPrint: React.FC<ISellInvoice> = ({
  sellDetails,
  customerDetails,
  lines,
  invoiceLayouts,
  paymentType,
  handleModalClose
}) => {
  const printRef = useRef<any>();

  const loggedInUser = getUserData();

  const content = JSON.parse(invoiceLayouts?.content);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      await updatePrintCountMutation.mutateAsync(sellDetails.id);
    }
  });

  const updatePrintCountMutation = useMutation(update_sell_print_count_mutation, {
    onSuccess: () => {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const linesTotalDiscount = lines.reduce((acc, curr) => acc + Number(curr.discount), 0);
  const isPrintCopy = sellDetails.printCount > 0;
  const printedTime = moment().format('DD/MM/YYYY hh:mm:ss A');

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        <div className="my-2 mx-5 font-pt-sans-narrow" style={{ color: 'black' }}>
          <div className="text-center font-extrabold leading-tight">
            {content?.headerText || 'FreshKtm Agro Pvt. Ltd.'}
          </div>
          <div className="text-center font-bold leading-tight">
            {content?.subHeaderText || 'Suryabhinayak, Bhaktapur, Nepal'}
          </div>
          <div className="text-center font-bold leading-tight">
            {sellDetails.printLocation?.name}
          </div>
          <div className="text-center font-bold leading-tight">PAN:- {find_invoice_number()}</div>
          <div className="mb-2 mt-3 relative leading-tight">
            <div className="text-center font-bold">{isPrintCopy ? 'INVOICE' : 'TAX INVOICE'}</div>

            {isPrintCopy && (
              <div className="original-copy-style text-black/80">
                Copy of Original {sellDetails.printCount}
              </div>
            )}
          </div>

          <div className="grid grid-cols-3 gap-x-0 mt-1 font-semibold text-sm">
            <span className="leading-tight">{'Invoice No.'}</span>
            <span className="col-span-2 leading-tight -ml-2">
              : {sellDetails.financialReference}
            </span>
            <span className="leading-tight">
              {content?.customerNameLabel ? content.customerNameLabel : 'Name'}
            </span>
            <span className="col-span-2 leading-tight -ml-2">: {customerDetails.name}</span>
            <span className="leading-tight">
              {content?.addressLabel ? content.addressLabel : 'Address'}
            </span>
            <span className="col-span-2 leading-tight -ml-2">
              : {customerDetails?.address} {customerDetails?.city},{customerDetails?.country}
            </span>
            <span className="leading-tight">{content?.dateLabel || 'Invoice Date'}</span>
            <span className="col-span-2 leading-tight -ml-2">
              : {convertUTCStringtoLocalString(sellDetails.createdAt)}
            </span>
            <span className="leading-tight">{content?.nepaliDateLabel || 'Invoice Miti'}</span>
            <span className="col-span-2 leading-tight -ml-2">
              :{' '}
              {sellDetails?.createdAt
                ? convert_string_to_nepali_date_string(sellDetails.createdAt)
                : convert_string_to_nepali_date_string(moment().format())}
            </span>
            <span className="leading-tight">
              {content?.transactionDateLabel || 'Transaction Date'}
            </span>
            <span className="col-span-2 leading-tight -ml-2">
              : {convertUTCStringtoLocalString(sellDetails.createdAt)}
            </span>
            <span className="leading-tight">
              {content?.panNumberLabel ? content.panNumberLabel : 'PAN No.'}
            </span>
            <span className="col-span-2 leading-tight -ml-2">
              : {customerDetails?.panNumber ? customerDetails.panNumber : ''}
            </span>
            <span className="col-span-3 leading-tight">
              {content?.paymentLabel} : {paymentType}
            </span>
            <div className="col-span-3 font-semibold text-sm leading-tight">
              {content?.remarksLabel}:{' '}
            </div>
          </div>
          {/* Table Start */}
          <div style={{ color: 'black', width: '95%' }}>
            <div className="flex justify-between border-dashed border-y-1 border-x-0 py-1 border-black mt-2">
              <span className="flex justify-between" style={{ flex: 1 }}>
                <span className="font-semibold text-sm">{'Sn'}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
              <span className="flex justify-evenly" style={{ flex: 3 }}>
                <span className="font-semibold text-sm">{content?.hsCodeLabel || 'HS Code'}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
              <span className="flex justify-evenly" style={{ flex: 7 }}>
                <span className="font-semibold text-sm">{content?.productLabel}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
              <span className="flex justify-evenly" style={{ flex: 2 }}>
                <span className="font-semibold text-sm">{content?.quantityLabel}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
              <span className="flex justify-evenly" style={{ flex: 3 }}>
                <span className="font-semibold text-sm">{content?.unitPriceLabel}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
              <span className="flex justify-between" style={{ flex: 3 }}>
                <span className="font-semibold text-sm">{content?.subTotalLabel}</span>
                <span className="font-semibold text-sm">|</span>
              </span>
            </div>
            {lines.map((curr, ind) => {
              return (
                <div key={ind} style={{ display: 'flex' }}>
                  <span className="font-semibold text-sm" style={{ flex: 1 }}>
                    {ind + 1}
                  </span>
                  <span className="font-semibold text-sm" style={{ flex: 3 }}>
                    {curr?.hsCode || 'N/A'}
                  </span>
                  <span className="font-semibold text-sm" style={{ flex: 7 }}>
                    {content?.productNameDisplayType &&
                    content?.productNameDisplayType === 'partial'
                      ? content?.productNameDisplay === 'initial'
                        ? getShortName(curr.productName ? curr.productName : '')
                        : content?.productNameDisplay === 'final'
                        ? getShortLastName(curr.productName ? curr.productName : '')
                        : curr.productName
                        ? curr.productName
                        : ''
                      : curr.productName
                      ? curr.productName
                      : ''}
                    {curr.vat && curr.vat > 0 ? (
                      <span className="text-xl leading-none">{' *'}</span>
                    ) : null}
                  </span>

                  <span className="font-semibold text-sm" style={{ flex: 2, textAlign: 'center' }}>
                    {curr.quantity}
                  </span>
                  <span className="font-semibold text-sm" style={{ flex: 3, textAlign: 'center' }}>
                    {curr.unitPrice}
                  </span>
                  <span className="font-semibold text-sm" style={{ flex: 3, textAlign: 'center' }}>
                    {/* {curr.vat
                      ? (curr.totalAmount + curr.discount - curr.vat).toFixed(2)
                      : (curr.totalAmount + curr.discount).toFixed(2)} */}
                    {(curr.quantity * curr.unitPrice).toFixed(2)}
                  </span>
                </div>
              );
            })}
            <div className="flex justify-end">
              <div
                style={{ width: '70%', textAlign: 'left' }}
                className="m-0 p-0 border-dashed border-t-1 border-x-0 border-b-0 border-black">
                <table className="table w-full">
                  <tbody style={{ textAlign: 'right' }}>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">
                        {content?.grossAmount ? content.grossAmount : 'Amount'}
                      </td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {nepaliNumberFormatter(
                          sellDetails.totalTaxable +
                            sellDetails.totalNonTaxable +
                            sellDetails.totalDiscount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">
                        {content?.discountAmount ? content.discountAmount : 'Discount'}
                      </td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {sellDetails?.totalDiscount
                          ? nepaliNumberFormatter(sellDetails.totalDiscount)
                          : nepaliNumberFormatter(linesTotalDiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">Taxable</td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {sellDetails?.totalTaxable != undefined
                          ? nepaliNumberFormatter(sellDetails.totalTaxable)
                          : nepaliNumberFormatter(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">Non Taxable</td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {sellDetails?.totalNonTaxable != undefined
                          ? nepaliNumberFormatter(sellDetails.totalNonTaxable)
                          : nepaliNumberFormatter(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">VAT</td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {sellDetails?.totalVat != undefined
                          ? nepaliNumberFormatter(sellDetails.totalVat)
                          : nepaliNumberFormatter(0)}
                      </td>
                    </tr>
                    <tr className="border-dashed border-b-1 border-x-0 border-t-0 border-black">
                      <td className="font-semibold text-sm leading-tight">
                        {content?.netAmountLabel ? content.netAmountLabel : 'Net Amount'}
                      </td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {nepaliNumberFormatter(sellDetails.totalAmount)}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-semibold text-sm leading-tight">{'Tender Amount'}</td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {nepaliNumberFormatter(sellDetails.tenderAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold text-sm leading-tight">
                        {content?.changeLabel ? content.changeLabel : 'Change'}
                      </td>
                      <td className="font-semibold text-sm leading-tight">:</td>
                      <td className="font-semibold text-sm text-right leading-tight">
                        {nepaliNumberFormatter(sellDetails.tenderReturnAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-end border-dashed border-y-1 border-x-0 border-black font-semibold text-sm">
              <div style={{ width: '50%' }}>
                {content?.totalQuantityLabel} :{sellDetails.totalQuantity}
              </div>
            </div>

            <div className="border-dashed border-b-1 border-t-0 border-x-0 border-black font-semibold text-sm">
              {content?.footerText}
            </div>
            <div className="font-semibold text-sm">Cashier : {loggedInUser.name}</div>
            <div className="font-semibold text-sm my-2 flex justify-between gap-1 flex-wrap">
              <div>
                <span className="font-bold">Printed At:</span> {printedTime}
              </div>
              <div className="font-bold">E. & O.E</div>
            </div>
          </div>
          {/* Table End */}
        </div>
        {/* For First Print */}
        {sellDetails.printCount === 0 && (
          <>
            <div className="pagebreak"></div>
            <div className="my-2 mx-5 font-pt-sans-narrow" style={{ color: 'black' }}>
              <div className="text-center font-extrabold leading-tight">
                {content?.headerText || 'FreshKtm Agro Pvt. Ltd.'}
              </div>
              <div className="text-center font-bold leading-tight">
                {content?.subHeaderText || 'Suryabhinayak, Bhaktapur, Nepal'}
              </div>
              <div className="text-center font-bold leading-tight">
                {sellDetails.printLocation?.name}
              </div>
              <div className="text-center font-bold leading-tight">
                PAN:- {find_invoice_number()}
              </div>
              <div className="text-center font-bold leading-tight mt-3 mb-2">{'INVOICE'}</div>
              <div className="grid grid-cols-3 gap-x-0 mt-1 font-semibold text-sm">
                <span className="leading-tight">{'Invoice No.'}</span>
                <span className="col-span-2 leading-tight -ml-2">
                  : {sellDetails.financialReference}
                </span>
                <span className="leading-tight">
                  {content?.customerNameLabel ? content.customerNameLabel : 'Name'}
                </span>
                <span className="col-span-2 leading-tight -ml-2">: {customerDetails.name}</span>
                <span className="leading-tight">
                  {content?.addressLabel ? content.addressLabel : 'Address'}
                </span>
                <span className="col-span-2 leading-tight -ml-2">
                  : {customerDetails?.address} {customerDetails?.city},{customerDetails?.country}
                </span>
                <span className="leading-tight">{content?.dateLabel || 'Invoice Date'}</span>
                <span className="col-span-2 leading-tight -ml-2">
                  : {convertUTCStringtoLocalString(sellDetails.createdAt)}
                </span>
                <span className="leading-tight">{content?.nepaliDateLabel || 'Invoice Miti'}</span>
                <span className="col-span-2 leading-tight -ml-2">
                  :{' '}
                  {sellDetails?.createdAt
                    ? convert_string_to_nepali_date_string(sellDetails.createdAt)
                    : convert_string_to_nepali_date_string(moment().format())}
                </span>
                <span className="leading-tight">
                  {content?.transactionDateLabel || 'Transaction Date'}
                </span>
                <span className="col-span-2 leading-tight -ml-2">
                  : {convertUTCStringtoLocalString(sellDetails.createdAt)}
                </span>
                <span className="leading-tight">
                  {content?.panNumberLabel ? content.panNumberLabel : 'PAN No.'}
                </span>
                <span className="col-span-2 leading-tight -ml-2">
                  : {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                </span>
                <span className="col-span-3 leading-tight">
                  {content?.paymentLabel} : {paymentType}
                </span>
                <div className="col-span-3 font-semibold text-sm leading-tight">
                  {content?.remarksLabel}:{' '}
                </div>
              </div>
              {/* Table Start */}
              <div style={{ color: 'black', width: '95%' }}>
                <div className="flex justify-between border-dashed border-y-1 border-x-0 py-1 border-black mt-2">
                  <span className="flex justify-between" style={{ flex: 1 }}>
                    <span className="font-semibold text-sm">{'Sn'}</span>
                    <span className="font-semibold text-sm">|</span>
                  </span>
                  <span className="flex justify-evenly" style={{ flex: 3 }}>
                    <span className="font-semibold text-sm">
                      {content?.hsCodeLabel || 'HS Code'}
                    </span>
                    <span className="font-semibold text-sm">|</span>
                  </span>
                  <span className="flex justify-evenly" style={{ flex: 7 }}>
                    <span className="font-semibold text-sm">{content?.productLabel}</span>
                    <span className="font-semibold text-sm">|</span>
                  </span>

                  <span className="flex justify-evenly" style={{ flex: 2 }}>
                    <span className="font-semibold text-sm">{content?.quantityLabel}</span>
                    <span className="font-semibold text-sm">|</span>
                  </span>
                  <span className="flex justify-evenly" style={{ flex: 3 }}>
                    <span className="font-semibold text-sm">{content?.unitPriceLabel}</span>
                    <span className="font-semibold text-sm">|</span>
                  </span>
                  <span className="flex justify-between" style={{ flex: 3 }}>
                    <span className="font-semibold text-sm">{content?.subTotalLabel}</span>
                    <span className="font-semibold text-sm">|</span>
                  </span>
                </div>
                {lines.map((curr, ind) => {
                  return (
                    <div key={ind} style={{ display: 'flex' }}>
                      <span className="font-semibold text-sm" style={{ flex: 1 }}>
                        {ind + 1}
                      </span>
                      <span className="font-semibold text-sm" style={{ flex: 3 }}>
                        {curr?.hsCode || 'N/A'}
                      </span>
                      <span className="font-semibold text-sm" style={{ flex: 7 }}>
                        {content?.productNameDisplayType &&
                        content?.productNameDisplayType === 'partial'
                          ? content?.productNameDisplay === 'initial'
                            ? getShortName(curr.productName ? curr.productName : '')
                            : content?.productNameDisplay === 'final'
                            ? getShortLastName(curr.productName ? curr.productName : '')
                            : curr.productName
                            ? curr.productName
                            : ''
                          : curr.productName
                          ? curr.productName
                          : ''}
                        {curr.vat && curr.vat > 0 ? (
                          <span className="text-xl leading-none">{' *'}</span>
                        ) : null}
                      </span>

                      <span
                        className="font-semibold text-sm"
                        style={{ flex: 2, textAlign: 'center' }}>
                        {curr.quantity}
                      </span>
                      <span
                        className="font-semibold text-sm"
                        style={{ flex: 3, textAlign: 'center' }}>
                        {curr.unitPrice}
                      </span>
                      <span
                        className="font-semibold text-sm"
                        style={{ flex: 3, textAlign: 'center' }}>
                        {/* {curr.vat
                          ? (curr.totalAmount + curr.discount - curr.vat).toFixed(2)
                          : (curr.totalAmount + curr.discount).toFixed(2)} */}
                        {(curr.quantity * curr.unitPrice).toFixed(2)}
                      </span>
                    </div>
                  );
                })}
                <div className="flex justify-end">
                  <div
                    style={{ width: '70%', textAlign: 'left' }}
                    className="m-0 p-0 border-dashed border-t-1 border-x-0 border-b-0 border-black">
                    <table className="table w-full">
                      <tbody style={{ textAlign: 'right' }}>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">
                            {content?.grossAmount ? content.grossAmount : 'Amount'}
                          </td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {nepaliNumberFormatter(
                              sellDetails.totalTaxable +
                                sellDetails.totalNonTaxable +
                                sellDetails.totalDiscount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">
                            {content?.discountAmount ? content.discountAmount : 'Discount'}
                          </td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {sellDetails?.totalDiscount
                              ? nepaliNumberFormatter(sellDetails.totalDiscount)
                              : nepaliNumberFormatter(linesTotalDiscount)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">Taxable</td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {sellDetails?.totalTaxable != undefined
                              ? nepaliNumberFormatter(sellDetails.totalTaxable)
                              : nepaliNumberFormatter(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">Non Taxable</td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {sellDetails?.totalNonTaxable != undefined
                              ? nepaliNumberFormatter(sellDetails.totalNonTaxable)
                              : nepaliNumberFormatter(0)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">VAT</td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {sellDetails?.totalVat != undefined
                              ? nepaliNumberFormatter(sellDetails.totalVat)
                              : nepaliNumberFormatter(0)}
                          </td>
                        </tr>
                        <tr className="border-dashed border-b-1 border-x-0 border-t-0 border-black">
                          <td className="font-semibold text-sm leading-tight">
                            {content?.netAmountLabel ? content.netAmountLabel : 'Net Amount'}
                          </td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {nepaliNumberFormatter(sellDetails.totalAmount)}
                          </td>
                        </tr>

                        <tr>
                          <td className="font-semibold text-sm leading-tight">{'Tender Amount'}</td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {nepaliNumberFormatter(sellDetails.tenderAmount)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-semibold text-sm leading-tight">
                            {content?.changeLabel ? content.changeLabel : 'Change'}
                          </td>
                          <td className="font-semibold text-sm leading-tight">:</td>
                          <td className="font-semibold text-sm text-right leading-tight">
                            {nepaliNumberFormatter(sellDetails.tenderReturnAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-end border-dashed border-y-1 border-x-0 border-black font-semibold text-sm">
                  <div style={{ width: '50%' }}>
                    {content?.totalQuantityLabel} :{sellDetails.totalQuantity}
                  </div>
                </div>
                <div className="border-dashed border-b-1 border-t-0 border-x-0 border-black font-semibold text-sm">
                  {content?.footerText}
                </div>
                <div className="font-semibold text-sm">Cashier : {loggedInUser.name}</div>
                <div className="font-semibold text-sm my-2 flex justify-between gap-1 flex-wrap">
                  <div>
                    <span className="font-bold">Printed At:</span> {printedTime}
                  </div>
                  <div className="font-bold">E. & O.E</div>
                </div>
              </div>
              {/* Table End */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BillPrint;
